import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Chip,
} from '@mui/material'
import {
  DevicesOther,
  ExpandLess,
  ExpandMore,
  Timeline,
  Event,
  AccessAlarm,
  // BallotOutlined,
} from '@mui/icons-material'
import { Link } from 'react-router-dom'

import { useEvent } from '../../contexts/event'

const drawerWidth = 220

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  linkHolder: {
    textDecoration: 'none',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const Navegator = (props) => {
  const classes = useStyles()
  const { notifications } = useEvent()

  const [devicesList, setDevicesList] = useState(false)

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="persistent"
      open={props.openDrawer}
      onClose={() => props.setOpenDrawer(false)}
    >
      <div className={classes.toolbar} />
      <List>
        <ListItem
          button
          key="devices"
          onClick={() => setDevicesList(!devicesList)}
        >
          <ListItemIcon>
            <DevicesOther />
          </ListItemIcon>
          <ListItemText primary="Dispositivos" />
          {devicesList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={devicesList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              key="devicePending"
              className={classes.nested}
              component={React.forwardRef((props, ref) => {
                return (
                  <Link
                    className={classes.linkHolder}
                    to="/device/pending"
                    {...props}
                    ref={ref}
                  />
                )
              })}
            >
              <ListItemIcon>
                <DevicesOther />
              </ListItemIcon>
              <ListItemText primary="Pendentes" />
            </ListItem>
            <ListItem
              button
              key="deviceRegistered"
              className={classes.nested}
              component={React.forwardRef((props, ref) => {
                return (
                  <Link
                    className={classes.linkHolder}
                    to="/device/registered"
                    {...props}
                    ref={ref}
                  />
                )
              })}
            >
              <ListItemIcon>
                <DevicesOther />
              </ListItemIcon>
              <ListItemText primary="Cadastrados" />
            </ListItem>
            <ListItem
              button
              key="deviceHistory"
              className={classes.nested}
              component={React.forwardRef((props, ref) => {
                return (
                  <Link
                    className={classes.linkHolder}
                    to="/history"
                    {...props}
                    ref={ref}
                  />
                )
              })}
            >
              <ListItemIcon>
                <Timeline />
              </ListItemIcon>
              <ListItemText primary="Histórico" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          button
          key="deviceEvents"
          className={classes.toolbar}
          component={React.forwardRef((props, ref) => {
            return (
              <Link
                className={classes.linkHolder}
                to="/events"
                {...props}
                ref={ref}
              />
            )
          })}
        >
          <ListItemIcon>
            <Event />
          </ListItemIcon>
          <ListItemText primary="Eventos" />
        </ListItem>
        <ListItem
          button
          key="deviceAlarms"
          className={classes.toolbar}
          component={React.forwardRef((props, ref) => {
            return (
              <Link
                className={classes.linkHolder}
                to="/alarms"
                {...props}
                ref={ref}
              />
            )
          })}
        >
          <ListItemIcon>
            <AccessAlarm />
          </ListItemIcon>
          <ListItemText primary="Alarmes" />
          <Chip color="primary" size="small" label={notifications.length} />
        </ListItem>
        {/*<ListItem*/}
        {/*  button*/}
        {/*  key="rules"*/}
        {/*  className={classes.toolbar}*/}
        {/*  component={React.forwardRef((props, ref) => {*/}
        {/*    return (*/}
        {/*      <Link*/}
        {/*        className={classes.linkHolder}*/}
        {/*        to="/rules"*/}
        {/*        {...props}*/}
        {/*        ref={ref}*/}
        {/*      />*/}
        {/*    )*/}
        {/*  })}*/}
        {/*>*/}
        {/*  <ListItemIcon>*/}
        {/*    <BallotOutlined />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="Regras" />*/}
        {/*</ListItem>*/}
      </List>
    </Drawer>
  )
}

export default Navegator
