import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Collapse,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GetAppIcon from '@mui/icons-material/GetApp'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import moment from 'moment'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

const Graphic = ({ history, onDelete, download }) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(true)

  const displayLines = () => {
    if (history.sensor.type === 'heatmap' && history.data[0]) {
      return Object.keys(history.data[0]).map(
        (key, index) =>
          key === 'time' || (
            <Line
              key={key}
              name={`${history.sensor.name}-${key.slice(-1)}`}
              type="monotone"
              dataKey={key}
              stroke={COLORS[index % COLORS.length]}
            />
          )
      )
    }

    return (
      <Line
        name={history.sensor.name}
        type="monotone"
        dataKey="value"
        stroke="#008585"
      />
    )
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <div>
            <IconButton onClick={download}>
              <GetAppIcon />
            </IconButton>
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={() => setExpanded(!expanded)}
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        }
        title={history.device.name}
        subheader={history.sensor.name}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ height: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={history.data}>
              <CartesianGrid />
              <XAxis
                dataKey="time"
                domain={['dataMin', 'dataMax']}
                tickFormatter={(unixTime) => moment(unixTime).format('HH:mm')}
                type="number"
                unit={history.sensor.unit}
                padding={{ left: 30 }}
              />
              <YAxis domain={['dataMin', 'dataMax']} padding={{ bottom: 30 }} />
              <Tooltip
                labelFormatter={(value) =>
                  moment(value).format('DD/MM/YYYY HH:mm:ss:SS')
                }
                formatter={(value) => [value, 'Valor']}
              />
              <Legend />
              {displayLines()}
            </LineChart>
          </ResponsiveContainer>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default Graphic
