import React, { createContext, useContext, useEffect, useState } from 'react'
import io from 'socket.io-client'

import { useAuth } from './auth'

const SocketContext = createContext({})

export const SocketProvider = (props) => {
  const { logged } = useAuth()

  const [socket, setSocket] = useState()

  useEffect(() => {
    function startSocket() {
      if (socket) {
        socket.open()
      } else {
        setSocket(
          io(process.env.REACT_APP_SOCKET_URL, { withCredentials: true })
        )
      }
    }

    if (logged) {
      startSocket()
    } else {
      if (socket) {
        socket.close()
      }
    }
  }, [logged, socket])

  return (
    <SocketContext.Provider
      value={{
        socket,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  )
}

export function useSocket() {
  const context = useContext(SocketContext)
  return context
}
