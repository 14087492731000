import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { PhonelinkOff } from '@mui/icons-material'

import DeviceCard from './DeviceCard'
import FilterBar from './FilterBar'
import { useDevice } from '../../contexts/device'
import { requestApi } from '../../helpers'
import { NoData } from '../../components/NoData'

const useStyles = makeStyles(() => ({
  devices: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 360px))',
    justifyContent: 'center',
  },
}))

const Devices = () => {
  const classes = useStyles()

  const { devices, setDevices } = useDevice()

  const online = useState(true)
  const offline = useState(true)
  const search = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [snackbarMessage, setSnackbarMessage] = useState('Mensagem padrão')

  const selfDelete = async (id) => {
    try {
      await requestApi.delete(`/device/${id}`)

      let result = true

      setDevices((prevState) => {
        const deviceToDeleteIndex = prevState.findIndex(
          (element) => element.id === id
        )
        if (deviceToDeleteIndex !== -1) {
          prevState.splice(deviceToDeleteIndex, 1)
          return [...prevState]
        } else {
          result = false
          return prevState
        }
      })

      if (result) {
        setSnackbarMessage('Dispositivo apagado com sucesso!')
      } else {
        setSnackbarMessage('Erro ao apagar o dispositivo')
        setSnackbarSeverity('error')
      }
      setOpenSnackbar(true)
    } catch (err) {
      setOpenSnackbar(true)
      setSnackbarMessage('Erro ao apagar o dispositivo')
      setSnackbarSeverity('error')
      console.log(err)
    }
  }

  const getDevices = () => {
    let array = []
    if (online[0]) {
      devices
        .filter(
          (device) =>
            device.online &&
            device.name
              .toLocaleLowerCase()
              .includes(search[0].toLocaleLowerCase())
        )
        .forEach((device) => {
          array.push(
            <DeviceCard
              key={device.id}
              device={device}
              selfDelete={() => selfDelete(device.id)}
            />
          )
        })
    }
    if (offline[0]) {
      devices
        .filter(
          (device) =>
            !device.online &&
            device.name
              .toLocaleLowerCase()
              .includes(search[0].toLocaleLowerCase())
        )
        .forEach((device) => {
          array.push(
            <DeviceCard
              key={device.id}
              device={device}
              selfDelete={() => selfDelete(device.id)}
            />
          )
        })
    }

    if (array.length) {
      return array
    }

    return <p style={{ textAlign: 'center' }}>Sem dispositivos</p>
  }

  return (
    <>
      {devices.length ? (
        <div>
          <FilterBar
            onlineState={online}
            offlineState={offline}
            searchState={search}
          />
          <div className={classes.devices}>{getDevices()}</div>
        </div>
      ) : (
        <NoData Icon={PhonelinkOff} subject="dispositivo" />
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </>
  )
}

export default Devices
