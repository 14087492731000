import React, { createContext, useContext, useState, useEffect } from 'react'

import { requestApi } from '../helpers'

const AuthContext = createContext({})

export const AuthProvider = (props) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function verifyLogged() {
      try {
        const response = await requestApi.get('/user')

        setUser(response.data)
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    }

    verifyLogged()
  }, [])

  async function login(email, password) {
    try {
      await requestApi.post('/auth/user/login', {
        email,
        password,
      })
      const response = await requestApi.get('/user')

      setUser(response.data)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  async function logout() {
    setUser(null)
    if (!!user) {
      await requestApi.post('/auth/user/logout')
    }
  }

  return (
    <AuthContext.Provider
      value={{ logged: !!user, user, loading, login, logout }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  return context
}
