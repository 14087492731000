import React, { useEffect, useState } from 'react'
import { Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { saveAs } from 'file-saver'
import { nanoid } from 'nanoid'

import { useDevice } from '../../contexts/device'
import { requestApi } from '../../helpers/index'
import DeviceForm from './DeviceForm'
import Graphic from './Graphic'
import Map from './Map'
import { PhonelinkOff } from '@mui/icons-material'
import { NoData } from '../../components/NoData'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  dateRage: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    margin: 10,
  },
  deviceForm: {
    padding: 10,
    margin: 10,
  },
  graphics: {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  graphic: {
    position: 'relative',
    padding: 20,
    margin: '10px 0px',
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    cursor: 'pointer',
  },
}))

const processData = (data, sensorType) => {
  switch (sensorType) {
    case 'number': {
      return data.map((element) => ({
        value: Number(element.value),
        time: moment(element.time).valueOf(),
      }))
    }
    case 'on/off': {
      return data.map((element) => ({
        value: element.value === 'true' ? 1 : 0,
        time: moment(element.time).valueOf(),
      }))
    }
    case 'gps': {
      return data.map((element) => {
        return {
          time: moment(element.time).valueOf(),
          value: JSON.parse(element.value),
        }
      })
    }
    case 'heatmap': {
      return data.map((element) => {
        const data = { time: moment(element.time).valueOf() }

        for (const [index, value] of element.value.split(',').entries()) {
          data[`value${index}`] = Number(value)
        }

        return data
      })
    }
    default: {
      return {}
    }
  }
}

const DeviceHistory = () => {
  const classes = useStyles()

  const { devices } = useDevice()

  const [devicesWithSensors, setDevicesWithSensors] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [history, setHistory] = useState([])

  useEffect(() => {
    if (devices.length) {
      let newDevicesWithSensors = [
        ...devices.filter((element) => Object.keys(element.sensors).length > 0),
      ]
      setDevicesWithSensors(newDevicesWithSensors)
    }
  }, [devices])

  useEffect(() => {
    setStartDate(moment().startOf('day'))
    setEndDate(moment().endOf('day'))
  }, [])

  const getHistory = async (device, sensor, sensorId, download) => {
    try {
      let url = `device/history/${
        device.id
      }?startDate=${startDate.format()}&endDate=${endDate.format()}&sensorsKeys[]=${sensorId}`
      if (download) {
        url += '&download=true'
        let response = await requestApi.get(url, { responseType: 'blob' })
        saveAs(response.data, `${device.id}.zip`)
      } else {
        let response = await requestApi.get(url)

        const data = processData(response.data[sensorId], sensor.type)

        sensor.id = sensorId

        let obj = {
          device,
          sensor,
          data,
        }
        setHistory((history) => {
          history.push(obj)
          return [...history]
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteDevice = (index) => {
    setHistory((history) => {
      history.splice(index, 1)
      return [...history]
    })
  }

  const defineDate = (rangeDate) => {
    setStartDate(moment(rangeDate[0].startDate.toISOString()))
    setEndDate(moment(rangeDate[0].endDate.toISOString()).endOf('day'))
  }

  return (
    <>
      {devicesWithSensors.length ? (
        <div className={classes.container}>
          <div className={classes.menu}>
            <Paper className={classes.deviceForm}>
              <DeviceForm
                devices={devicesWithSensors}
                addDevice={getHistory}
                defineDate={defineDate}
              />
            </Paper>
          </div>
          <div className={classes.graphics}>
            {history.map((element, index) => {
              if (element.sensor.type !== 'gps') {
                return (
                  <Graphic
                    key={nanoid()}
                    history={element}
                    onDelete={() => deleteDevice(index)}
                    download={() =>
                      getHistory(
                        element.device,
                        element.sendor,
                        element.sensor.id,
                        true
                      )
                    }
                  />
                )
              }
              return (
                <Map
                  key={nanoid()}
                  history={element}
                  onDelete={() => deleteDevice(index)}
                  download={() =>
                    getHistory(
                      element.device,
                      element.sendor,
                      element.sensor.id,
                      true
                    )
                  }
                />
              )
            })}
          </div>
        </div>
      ) : (
        <NoData Icon={PhonelinkOff} subject="dispositivo com sensores" />
      )}
    </>
  )
}

export default DeviceHistory
