import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { DeviceProvider } from '../contexts/device'
import EventProvider from '../contexts/event'
import Home from '../components/Home'
import Devices from '../pages/Devices'
import Pending from '../pages/Pending'
import DeviceForm from '../components/DeviceForm'
import History from '../pages/History'
import Events from '../pages/Events'
import Alarms from '../pages/Alarms'
// import Rules from '../pages/Rules'
// import NewRule from '../pages/Rules/NewRule'
import LostRoute from './LostRoute'

const PrivateRoutes = () => {
  return (
    <DeviceProvider>
      <EventProvider>
        <Home>
          <Switch>
            <Route exact path="/device/registered" component={Devices} />
            <Route exact path="/device/registered/:id" component={DeviceForm} />
            <Route exact path="/device/pending" component={Pending} />
            <Route exact path="/device/pending/:id" component={DeviceForm} />
            <Route exact path="/history" component={History} />
            <Route exact path="/events" component={Events} />
            <Route exact path="/alarms" component={Alarms} />
            {/*<Route exact path="/rules" component={Rules} />*/}
            {/*<Route exact path="/rules/new" component={NewRule} />*/}
            <Route
              render={(props) => (
                <LostRoute {...props} to="/device/registered" />
              )}
            />
          </Switch>
        </Home>
      </EventProvider>
    </DeviceProvider>
  )
}

export default PrivateRoutes
