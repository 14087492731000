import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import DeleteIcon from '@mui/icons-material/Delete'
import { Link } from 'react-router-dom'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import * as locales from 'react-date-range/dist/locale'
import { DateRange } from 'react-date-range'
import { saveAs } from 'file-saver'

import Module from './Module'
import { requestApi } from '../../helpers'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  card: {
    color: '#FFFFFF',
    margin: 15,
    userSelect: 'none',
  },
  cardHeader: { backgroundColor: '#006064', color: '#FFF' },
  cardHeaderTitle: { display: 'flex' },
  cardHeaderTitleText: { flexGrow: 1 },
  cardHeaderTitleState: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 5px',
  },
  cardHeaderSubHeader: {
    height: 24,
  },
  linkHolder: {
    backgroundColor: '#4D9699',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    textDecoration: 'none',
  },
  cardContent: { padding: 0 },
  linkHolderText: { flexGrow: 1 },
  cardContentContent: { padding: 16 },
  moreMenuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  moreMenuItemIcon: {
    marginRight: 5,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogTextField: {
    margin: 10,
  },
  deleteDeviceButton: {
    backgroundColor: '#f0524a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#ce4841',
      boxShadow: 'none',
    },
  },
}))

const DeviceCard = ({ device, selfDelete }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
      color: '#008585',
    },
  ])
  const [maxData, setMaxData] = useState('')

  const getList = () => {
    const { actuators, sensors } = device
    let array = []

    for (let id in actuators) {
      array.push(
        <Module
          key={`module-actuator-${id}`}
          deviceId={device.id}
          id={id}
          type="actuator"
          module={actuators[id]}
        />
      )
    }

    for (let id in sensors) {
      array.push(
        <Module
          key={`module-sensor-${id}`}
          deviceId={device.id}
          id={id}
          type="sensor"
          module={sensors[id]}
        />
      )
    }

    return array
  }

  const download = async () => {
    let url = `${process.env.REACT_APP_API_URL}/device/history/${device.id}?download=true`
    const [{ startDate, endDate }] = rangeDate
    if (startDate && endDate) {
      let start = moment(startDate.toISOString()).format()
      let end = moment(endDate.toISOString()).format()
      url += `&startDate=${start}&endDate=${end}`
    }
    if (maxData.length) {
      url += `&qty=${maxData}`
    }
    try {
      let response = await requestApi.get(url, { responseType: 'blob' })
      saveAs(response.data, `${device.id}.zip`)
    } catch (error) {
      console.log(error)
    }

    setDownloadDialogOpen(false)
  }

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          subheaderTypographyProps={{ color: 'inherit' }}
          title={
            <div className={classes.cardHeaderTitle}>
              <Tooltip
                title={device.name.length > 20 ? device.name : ''}
                placement="top-start"
              >
                <div className={classes.cardHeaderTitleText}>
                  {device.name.length > 20
                    ? `${device.name.substring(0, 20)}...`
                    : device.name}
                </div>
              </Tooltip>
              <div
                className={classes.cardHeaderTitleState}
                style={{
                  color: device.online ? '#00c34e' : '#F0524A',
                }}
              >
                <FiberManualRecordIcon />
                {device.online ? 'ON' : 'OFF'}
              </div>
              <div>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  <MoreVertIcon style={{ color: '#fff' }} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(false)}
                >
                  <MenuItem
                    className={classes.moreMenuItem}
                    onClick={() => {
                      setAnchorEl(null)
                      setDownloadDialogOpen(true)
                    }}
                  >
                    <InsertDriveFileIcon className={classes.moreMenuItemIcon} />
                    Download CSV
                  </MenuItem>
                  <MenuItem
                    className={classes.moreMenuItem}
                    onClick={() => {
                      setAnchorEl(null)
                      setDeleteDialogOpen(true)
                    }}
                  >
                    <DeleteIcon className={classes.moreMenuItemIcon} />
                    Apagar Dispositivo
                  </MenuItem>
                </Menu>
              </div>
            </div>
          }
          subheader={
            <Tooltip
              title={
                !!device.description && device.description.length > 25
                  ? device.description
                  : ''
              }
              placement="top-start"
            >
              <div className={classes.cardHeaderSubHeader}>
                {!!device.description && device.description.length > 25
                  ? `${device.description.substring(0, 25)}...`
                  : device.description}
              </div>
            </Tooltip>
          }
        />
        <CardContent className={classes.cardContent} sx={{ p: 0 }}>
          <Link
            className={classes.linkHolder}
            to={`/device/registered/${device.id}`}
          >
            <span className={classes.linkHolderText}>Configurações</span>
            <ArrowForwardIcon />
          </Link>
          <div className={classes.cardContentContent}>{getList()}</div>
        </CardContent>
      </Card>
      <Dialog
        onClose={() => setDownloadDialogOpen(false)}
        open={downloadDialogOpen}
      >
        <DialogContent className={classes.dialogContent}>
          <TextField
            className={classes.dialogTextField}
            label="Quantidade maxima"
            value={maxData}
            type="number"
            onChange={(event) => setMaxData(event.target.value)}
            inputProps={{
              min: 1,
            }}
          />
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setRangeDate([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={rangeDate}
            locale={locales['pt']}
            startDatePlaceholder="Data de início"
            endDatePlaceholder="Data de fim"
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={download}>
            Download
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setDeleteDialogOpen(false)}
        open={deleteDialogOpen}
      >
        <DialogTitle>
          Tem certeza que deseja apagar esse dispositivo?
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          Todos os dados desse dispositivo serão apagados com ele e não será
          possivel reverter essa ação
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.deleteDeviceButton}
            color="error"
            variant="contained"
            onClick={async () => {
              setDeleteDialogOpen(false)
              selfDelete()
            }}
          >
            Apagar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setDeleteDialogOpen(false)
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeviceCard
