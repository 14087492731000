import React, { useState } from 'react'
import {
  Button,
  MenuItem,
  Select,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
  Snackbar,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import MuiAlert from '@mui/material/Alert'
import EventNoteIcon from '@mui/icons-material/EventNote'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import * as locales from 'react-date-range/dist/locale'
import { DateRange } from 'react-date-range'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  error: {
    color: '#DB4437',
    marginTop: 5,
  },
  content: {
    margin: '0px 10px',
  },
}))

const DeviceForm = ({ devices, addDevice, defineDate }) => {
  const classes = useStyles()

  const [device, setDevice] = useState(0)
  const [sensor, setSensor] = useState(0)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const [rangeDate, setRangeDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      color: '#008585',
    },
  ])
  const [openDateRange, setOpenDateRange] = useState(false)

  const checkData = () => {
    let errors = false
    if (device === 0) {
      errors = true
    }
    if (sensor === 0) {
      errors = true
    }
    if (!errors) {
      addDevice(device, device.sensors[sensor], sensor, false)
    } else {
      setOpenSnackbar(true)
    }
  }

  return (
    <div className={classes.container}>
      <span style={{ marginLeft: 10 }}>Selecionar período: </span>
      <Tooltip title="Escolher datas" placement="top">
        <IconButton
          className={classes.content}
          onClick={() => setOpenDateRange(true)}
        >
          <EventNoteIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Select
        className={classes.content}
        value={device.id || 0}
        defaultValue={0}
        onChange={(event) => {
          setDevice(() => {
            let newDevice = devices.find(
              (element) => element.id === event.target.value
            )
            if (newDevice) {
              return { ...newDevice }
            }
            return event.target.value
          })
        }}
      >
        <MenuItem value={0}>Selecione um dispositivo</MenuItem>
        {devices.map((device) => (
          <MenuItem key={device.id} value={device.id}>
            {device.name}
          </MenuItem>
        ))}
      </Select>
      <Select
        className={classes.content}
        value={sensor}
        defaultValue={0}
        onChange={(event) => setSensor(event.target.value)}
      >
        <MenuItem value={0}>Selecione um sensor</MenuItem>
        {device.sensors
          ? Object.keys(device.sensors).map((id) => (
              <MenuItem key={id} value={id}>
                {device.sensors[id].name}
              </MenuItem>
            ))
          : null}
      </Select>
      <Button
        className={classes.content}
        variant="contained"
        color="primary"
        onClick={checkData}
      >
        Adicionar dispositivo
      </Button>
      <Dialog open={openDateRange} onClose={() => setOpenDateRange(false)}>
        <DialogContent>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setRangeDate([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={rangeDate}
            locale={locales['pt']}
            startDatePlaceholder="Data de início"
            endDatePlaceholder="Data de fim"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenDateRange(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              defineDate(rangeDate)
              setOpenDateRange(false)
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity="error"
        >
          Preencha todos os campos
        </MuiAlert>
      </Snackbar>
    </div>
  )
}

export default DeviceForm
