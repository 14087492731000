import React, { createContext, useContext, useState, useEffect } from 'react'

import { useSocket } from './socket'
import { requestApi } from '../helpers'

const DeviceContext = createContext({})

export const DeviceProvider = (props) => {
  const { socket } = useSocket()

  const [devices, setDevices] = useState([])
  const [pendingDevices, setPendingDevices] = useState()

  useEffect(() => {
    const getDevices = async () => {
      try {
        const response = await requestApi.get('/device')
        setDevices(response.data)
      } catch (err) {
        console.log(err)
      }
    }

    const getPendings = async () => {
      try {
        const response = await requestApi.get('/device/pending')

        if (Object.entries(response.data).length === 0) {
          setPendingDevices(null)
        } else {
          setPendingDevices(response.data)
        }
      } catch (err) {
        return console.log(err.message)
      }
    }

    if (socket) {
      socket.on('newPendingDevice', (newPendingDevice) => {
        setPendingDevices((prevSatete) => ({
          ...prevSatete,
          ...newPendingDevice,
        }))
      })

      socket.on('deviceConnected', (deviceID) => {
        setDevices((prevState) => {
          let device = prevState.find((device) => device.id === deviceID)
          if (device) {
            device.online = true
          }

          return [...prevState]
        })
      })

      socket.on('deviceDisconnected', (deviceID) => {
        setDevices((prevState) => {
          let device = prevState.find((device) => device.id === deviceID)
          if (device) {
            device.online = false
          }

          return [...prevState]
        })
      })
    }

    getDevices()
    getPendings()
  }, [socket])

  function addPendingDevice(id) {
    setDevices((prevState) => {
      let device = pendingDevices[id]
      device.id = id
      prevState.push(device)

      return [...prevState]
    })
    removePendingDevice(id)
  }

  function removePendingDevice(id) {
    setPendingDevices((prevState) => {
      delete prevState[id]

      if (Object.entries(prevState).length === 0) {
        return null
      }

      return { ...prevState }
    })
  }

  function updateDevaice(config) {
    setDevices((prevState) => {
      let index = prevState.findIndex((device) => device.id === config.id)
      prevState[index] = config

      return [...prevState]
    })
  }

  return (
    <DeviceContext.Provider
      value={{
        devices,
        setDevices,
        pendingDevices,
        setPendingDevices,
        addPendingDevice,
        removePendingDevice,
        updateDevaice,
      }}
    >
      {props.children}
    </DeviceContext.Provider>
  )
}

export function useDevice() {
  const context = useContext(DeviceContext)
  return context
}
