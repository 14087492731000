import React, { useState } from 'react'
import { IconButton, Dialog } from '@mui/material'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import CloseIcon from '@mui/icons-material/Close'
import {
  MapContainer,
  TileLayer,
  CircleMarker,
  Tooltip as LeafletToolTip,
  Polyline,
} from 'react-leaflet'
import { nanoid } from 'nanoid'
import moment from 'moment'

const Map = ({ history }) => {
  const [fullscreen, setFullscreen] = useState(false)

  return (
    <>
      <MapContainer
        key={nanoid()}
        style={{ height: '100%' }}
        center={history.data[0] ? history.data[0].value : [0, 0]}
        zoom={16}
        scrollWheelZoom={true}
        maxZoom={18}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: 10,
            zIndex: '1000',
            padding: 0,
            width: 35,
            height: 35,
            backgroundColor: 'white',
          }}
          onClick={() => setFullscreen(true)}
        >
          <FullscreenIcon />
        </IconButton>

        {history.data.map(({ value: position, time }, index) => (
          <React.Fragment key={nanoid()}>
            <CircleMarker
              center={position}
              radius={3}
              pathOptions={{
                color: `rgba(0,170,0,${1 - index / history.data.length})`,
              }}
            >
              <LeafletToolTip direction="top">
                <p style={{ margin: '0 0 5px 0' }}>{`Index: ${index}`}</p>
                <p style={{ margin: '0 0 5px 0' }}>{`Hora: ${moment(
                  time
                ).format('DD/MM HH:mm')}`}</p>
                <p
                  style={{ margin: '0 0 5px 0' }}
                >{`Latitude: ${position[0].toFixed(4)}`}</p>
                <p style={{ margin: '0' }}>{`Longitude: ${position[1].toFixed(
                  4
                )}`}</p>
              </LeafletToolTip>
            </CircleMarker>
            <Polyline
              pathOptions={{
                color: `rgba(0,170,0,${1 - index / history.data.length})`,
              }}
              positions={history.data
                .map((entry) => entry.value)
                .filter(
                  (ele, index2) => index === index2 || index === index2 + 1
                )}
            />
          </React.Fragment>
        ))}
      </MapContainer>

      <Dialog
        fullWidth
        maxWidth={'xl'}
        open={fullscreen}
        onClose={() => setFullscreen(false)}
      >
        <MapContainer
          key={nanoid()}
          style={{ width: '100%', height: '90vh' }}
          center={history.data[0] ? history.data[0].value : [0, 0]}
          zoom={16}
          scrollWheelZoom={true}
          maxZoom={18}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <IconButton
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              zIndex: '1000',
              padding: 0,
              width: 35,
              height: 35,
              backgroundColor: 'white',
            }}
            onClick={() => setFullscreen(false)}
          >
            <CloseIcon />
          </IconButton>

          {history.data.map(({ value: position, time }, index) => (
            <React.Fragment key={nanoid()}>
              <CircleMarker
                center={position}
                radius={3}
                pathOptions={{
                  color: `rgba(0,170,0,${1 - index / history.data.length})`,
                }}
              >
                <LeafletToolTip direction="top">
                  <p style={{ margin: '0 0 5px 0' }}>{`Index: ${index}`}</p>
                  <p style={{ margin: '0 0 5px 0' }}>{`Hora: ${moment(
                    time
                  ).format('DD/MM HH:mm')}`}</p>
                  <p
                    style={{ margin: '0 0 5px 0' }}
                  >{`Latitude: ${position[0].toFixed(4)}`}</p>
                  <p style={{ margin: '0' }}>{`Longitude: ${position[1].toFixed(
                    4
                  )}`}</p>
                </LeafletToolTip>
              </CircleMarker>
              <Polyline
                pathOptions={{
                  color: `rgba(0,170,0,${1 - index / history.data.length})`,
                }}
                positions={history.data
                  .map((entry) => entry.value)
                  .filter(
                    (ele, index2) => index === index2 || index === index2 + 1
                  )}
              />
            </React.Fragment>
          ))}
        </MapContainer>
      </Dialog>
    </>
  )
}

export default Map
