import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Slider } from '@mui/material'

import useSmartSend from '../../hooks/useSmartSend'

const useStyles = makeStyles(() => ({
  sliderActuator: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
}))

const SliderActuator = ({ deviceId, id, min, max, step, value }) => {
  const classes = useStyles()
  const [sliderValue, setSliderValue] = useState(value)
  const sendDeviceValue = useSmartSend(deviceId, id)

  useEffect(() => {
    setSliderValue(value)
  }, [value])

  const marks = [
    {
      value: min,
      label: min.toString(),
    },
    {
      value: max,
      label: max.toString(),
    },
  ]

  const changeValue = async (_, newValue) => {
    if (newValue === sliderValue) return

    setSliderValue(newValue)
    sendDeviceValue([newValue])
  }

  return (
    <div className={classes.sliderActuator}>
      <Slider
        value={sliderValue}
        step={step}
        min={min}
        max={max}
        marks={marks}
        onChange={changeValue}
        valueLabelDisplay="auto"
      />
    </div>
  )
}

export default SliderActuator
