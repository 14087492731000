import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { TextField, Button, Card, CardContent } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'

import { useDevice } from '../../contexts/device'
import { requestApi } from '../../helpers'

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
  },
  cardTitle: {
    fontWeight: '500',
    fontFamily: 'Roboto',
  },
  cardArea: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, auto))',
    gridGap: 17,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginBottom: 10,
  },
  buttonGroup: {
    margin: '1.2rem 0',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
  },
}))

const DeviceForm = () => {
  const classes = useStyles()

  const history = useHistory()
  const { id } = useParams()

  const {
    devices,
    pendingDevices,
    addPendingDevice,
    removePendingDevice,
    updateDevaice,
  } = useDevice()

  const [type, setType] = useState()
  const [device, setDevice] = useState()
  const [actuators, setActuators] = useState([])
  const [sensors, setSensors] = useState([])

  useEffect(() => {
    setType(history.location.pathname.split('/')[2])

    let currentDevice

    if (type === 'registered') {
      currentDevice = devices.find((device) => device.id === id)
    } else if (type === 'pending') {
      if (pendingDevices) {
        currentDevice = pendingDevices[id]
      }
    }

    if (currentDevice) {
      setDevice({
        name: currentDevice.name,
        class: currentDevice.class,
        description: currentDevice.description || undefined,
        online: currentDevice.online,
      })

      let newActuators = []
      for (let id in currentDevice.actuators) {
        newActuators.push({ id, ...currentDevice.actuators[id] })
      }
      setActuators(newActuators)

      let newSensors = []
      for (let id in currentDevice.sensors) {
        newSensors.push({ id, ...currentDevice.sensors[id] })
      }
      setSensors(newSensors)
    }
  }, [history, type, id, devices, pendingDevices])

  const changeField = (type, variable, value, index) => {
    if (value.length < 255) {
      if (type === 'device') {
        setDevice((prevState) => {
          prevState[variable] = value
          return { ...prevState }
        })
      } else if (type === 'actuator') {
        setActuators((prevState) => {
          prevState[index][variable] = value
          return [...prevState]
        })
      } else if (type === 'sensor') {
        setSensors((prevState) => {
          prevState[index][variable] = value
          return [...prevState]
        })
      }
    }
  }

  const refreshPage = () => history.go(0)

  const goBack = () => {
    history.replace(`/devices/${type}`)
    refreshPage()
  }

  const addDevice = async (event) => {
    event.preventDefault()

    let deviceConfig = { ...device, sensors: {}, actuators: {} }

    actuators.forEach((actuator) => {
      const { id } = actuator
      delete actuator.id
      deviceConfig.actuators[id] = { ...actuator }
    })

    sensors.forEach((sensor) => {
      const { id } = sensor
      delete sensor.id

      if (!Number(sensor.timeout)) {
        delete sensor.timeout
      } else {
        sensor.timeout = Number(sensor.timeout)
      }

      if (!Number(sensor.deadband)) {
        delete sensor.deadband
      } else {
        sensor.deadband = Number(sensor.deadband)
      }

      deviceConfig.sensors[id] = { ...sensor }
    })

    try {
      await requestApi.post('/device/approve', {
        id,
        deviceConfig,
      })

      addPendingDevice(id)
    } catch (err) {
      console.log(err)
    }
    goBack()
  }

  const changeDevice = async (event) => {
    event.preventDefault()

    let deviceConfig = { id, ...device, sensors: {}, actuators: {} }
    let deviceConfigToSend = { ...device, sensors: {}, actuators: {} }

    actuators.forEach((actuator) => {
      const { id } = actuator
      delete actuator.id

      deviceConfig.actuators[id] = { ...actuator }

      delete actuator.value
      delete actuator.type
      delete actuator.parameters
      delete actuator.min
      delete actuator.max
      delete actuator.step
      deviceConfigToSend.actuators[id] = { ...actuator }
    })

    sensors.forEach((sensor) => {
      const { id } = sensor
      delete sensor.id

      if (!Number(sensor.timeout)) {
        delete sensor.timeout
      } else {
        sensor.timeout = Number(sensor.timeout)
      }

      if (!Number(sensor.deadband)) {
        delete sensor.deadband
      } else {
        sensor.deadband = Number(sensor.deadband)
      }

      deviceConfig.sensors[id] = { ...sensor }

      delete sensor.value
      delete sensor.type
      delete sensor.min
      delete sensor.max
      deviceConfigToSend.sensors[id] = { ...sensor }
    })

    delete deviceConfigToSend.online

    try {
      await requestApi.post(`/device/${id}/config`, {
        ...deviceConfigToSend,
      })

      goBack()
      updateDevaice(deviceConfig)
    } catch (err) {
      console.log(err)
    }
  }

  const denyDevice = async () => {
    try {
      await requestApi.post('/device/deny', { id })

      removePendingDevice(id)
    } catch (err) {
      console.log(err)
    }

    goBack()
  }
  return (
    <>
      {!!device ? (
        <>
          <form
            className={classes.form}
            id="device-form"
            onSubmit={type === 'pending' ? addDevice : changeDevice}
          >
            <p className={classes.cardTitle} style={{ marginTop: 0 }}>
              Dispositivo
            </p>
            <Card variant="outlined">
              <CardContent className={classes.cardContent}>
                <TextField
                  className={classes.textField}
                  required
                  fullWidth
                  autoFocus
                  label="Nome"
                  value={device.name}
                  onChange={(event) =>
                    changeField('device', 'name', event.target.value)
                  }
                />
                <TextField
                  className={classes.textField}
                  required
                  fullWidth
                  label="Classe"
                  value={`${device.class}`}
                  onChange={(event) =>
                    changeField('device', 'class', event.target.value)
                  }
                />
                <TextField
                  className={classes.textField}
                  label="Descrição do dispositivo"
                  value={device.description || ''}
                  onChange={(event) =>
                    changeField('device', 'description', event.target.value)
                  }
                />
              </CardContent>
            </Card>

            {actuators.length ? (
              <div>
                <p className={classes.cardTitle}>Atuadores</p>
                <div className={classes.cardArea}>
                  {actuators.map((actuator, index) => (
                    <Card key={index} variant="outlined">
                      <CardContent className={classes.cardContent}>
                        <TextField
                          className={classes.textField}
                          required
                          fullWidth
                          label="Name"
                          value={actuator.name}
                          onChange={(event) =>
                            changeField(
                              'actuator',
                              'name',
                              event.target.value,
                              index
                            )
                          }
                        />
                        <TextField
                          className={classes.textField}
                          disabled
                          label="Tipo"
                          value={actuator.type}
                        />
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </div>
            ) : null}
            {sensors.length ? (
              <div>
                <p className={classes.cardTitle}>Sensores</p>
                <div className={classes.cardArea}>
                  {sensors.map((sensor, index) => (
                    <Card key={index} variant="outlined">
                      <CardContent className={classes.cardContent}>
                        <TextField
                          className={classes.textField}
                          required
                          label="Name"
                          value={sensor.name}
                          onChange={(event) =>
                            changeField(
                              'sensor',
                              'name',
                              event.target.value,
                              index
                            )
                          }
                        />
                        <TextField
                          className={classes.textField}
                          label="Descrição do sensor"
                          value={sensor.description || ''}
                          onChange={(event) =>
                            changeField(
                              'sensor',
                              'description',
                              event.target.value,
                              index
                            )
                          }
                        />
                        <TextField
                          className={classes.textField}
                          disabled
                          label="Tipo"
                          value={sensor.type}
                        />
                        <TextField
                          className={classes.textField}
                          type="number"
                          label="Timeout"
                          value={sensor.timeout || ''}
                          inputProps={{
                            min: 0,
                          }}
                          onChange={(event) =>
                            changeField(
                              'sensor',
                              'timeout',
                              event.target.value,
                              index
                            )
                          }
                        />
                        <TextField
                          className={classes.textField}
                          type="number"
                          label="Deadband"
                          value={sensor.deadband || ''}
                          inputProps={{
                            min: 0,
                          }}
                          onChange={(event) =>
                            changeField(
                              'sensor',
                              'deadband',
                              event.target.value,
                              index
                            )
                          }
                        />
                        {sensor.type !== 'on/off' ? (
                          <TextField
                            className={classes.textField}
                            label="Unidade / Escala"
                            value={sensor.unit || ''}
                            onChange={(event) =>
                              changeField(
                                'sensor',
                                'unit',
                                event.target.value,
                                index
                              )
                            }
                          />
                        ) : null}
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </div>
            ) : null}
          </form>
          <div className={classes.buttonGroup}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form="device-form"
            >
              {type === 'pending' ? 'Adicionar' : 'Atualizar'}
            </Button>
            {type === 'pending' ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={denyDevice}
              >
                Rejeitar
              </Button>
            ) : null}
            <Button color="inherit" variant="contained" onClick={goBack}>
              Cancelar
            </Button>
          </div>
        </>
      ) : null}
    </>
  )
}

export default DeviceForm
