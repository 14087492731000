import React from 'react'
import { makeStyles } from '@mui/styles'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@mui/material'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects'
import { Link } from 'react-router-dom'

import { useDevice } from '../../contexts/device'
import { NoData } from '../../components/NoData'
import { PhonelinkOff } from '@mui/icons-material'

const useStyles = makeStyles(() => ({
  linkHolder: {
    textDecoration: 'none',
  },
}))

const Pending = () => {
  const classes = useStyles()

  const { pendingDevices } = useDevice()

  const getList = () => {
    let array = []
    for (let id in pendingDevices) {
      array.push(
        <ListItem
          button
          key={id}
          component={React.forwardRef((props, ref) => {
            return (
              <Link
                className={classes.linkHolder}
                to={`/device/pending/${id}`}
                {...props}
                ref={ref}
              />
            )
          })}
        >
          <ListItemAvatar>
            <Avatar>
              <EmojiObjectsIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={pendingDevices[id].name} />
        </ListItem>
      )
    }

    return array
  }

  return (
    <>
      {pendingDevices ? (
        <div>
          <p>Dispositivos pendentes: </p> <List>{getList()}</List>
        </div>
      ) : (
        <NoData Icon={PhonelinkOff} subject="dispositivo pendente" />
      )}
    </>
  )
}

export default Pending
