import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { Tooltip } from '@mui/material'

import NumberActuator from './NumberActuator'
import SwitchActuator from './SwitchActuator'
import ColorActuator from './ColorActuator'
import SliderActuator from './SliderActuator'
import { useSocket } from '../../contexts/socket'
import Map from '../../components/Map'
import HeatMapSensor from './HeatMapSensor'

const useStyles = makeStyles(() => ({
  name: { color: '#737373' },
  value: { color: '#000000', margin: '16px 5px' },
}))

const Module = ({ deviceId, id, type, module }) => {
  const classes = useStyles()

  const { socket } = useSocket()

  const [value, setValue] = useState(module.value)

  useEffect(() => {
    if (type === 'actuator') {
      socket.on('updateActuator', (act) => {
        if (act.deviceId === deviceId && act.actuatorId === id) {
          setValue(act.value)
        }
      })

      return () => socket.off('updateActuator')
    } else {
      socket.on('updateSensor', (measurements) => {
        if (
          measurements.deviceId === deviceId &&
          measurements.sensorId === id
        ) {
          setValue(measurements.value)
        }
      })
      return () => socket.off('updateSensor')
    }
  }, [type, socket, deviceId, id])

  const getActuator = () => {
    switch (module.type) {
      case 'switch': {
        return <SwitchActuator deviceId={deviceId} id={id} value={value} />
      }
      case 'number': {
        return <NumberActuator deviceId={deviceId} id={id} value={value} />
      }
      case 'color': {
        return <ColorActuator deviceId={deviceId} id={id} value={value} />
      }
      case 'slider': {
        return (
          <SliderActuator
            deviceId={deviceId}
            id={id}
            min={module.min}
            max={module.max}
            step={module.step}
            value={value}
          />
        )
      }
      default:
        return <></>
    }
  }

  const getSensor = () => {
    switch (module.type) {
      case 'on/off': {
        const labelOn = module.labelOn ?? 'Ligado'
        const labelOff = module.labelOff ?? 'Desligado'

        return value ? labelOn : labelOff
      }
      case 'number': {
        let sensorValue = '?'

        if (value !== null && value !== undefined) {
          sensorValue = value

          if (!!module.max) {
            sensorValue += ` / ${module.max}`
          }

          if (!!module.unit) {
            sensorValue += ` ${module.unit}`
          }
        }

        return sensorValue
      }
      case 'gps': {
        return <Map position={value} />
      }
      case 'heatmap': {
        return (
          <HeatMapSensor matrix={value} min={module.min} max={module.max} />
        )
      }
      default:
        return ''
    }
  }

  return (
    <div>
      <Tooltip title={module.description || ''}>
        <span className={classes.name}>{module.name}</span>
      </Tooltip>
      <div className={classes.value}>
        {type === 'actuator' ? getActuator() : getSensor()}
      </div>
    </div>
  )
}

export default Module
