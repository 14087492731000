import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Collapse,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GetAppIcon from '@mui/icons-material/GetApp'
import DeleteIcon from '@mui/icons-material/Delete'
import clsx from 'clsx'

import HistoryMap from '../../components/HistoryMap'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

const Graphic = ({ history, onDelete, download }) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(true)

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          action={
            <div>
              <IconButton onClick={download}>
                <GetAppIcon />
              </IconButton>
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={() => setExpanded(!expanded)}
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
          }
          title={history.device.name}
          subheader={history.sensor.name}
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent style={{ height: 300 }}>
            <HistoryMap history={history} />
          </CardContent>
        </Collapse>
      </Card>
    </>
  )
}

export default Graphic
